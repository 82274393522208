import {
  RichtextChangeEventDetail,
  RichtextSelectionChangeEventDetail,
  RichtextUploadRequestEventDetail,
} from '@wppopen/components-library'
import { WppRichtextCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppButton, WppIconExport, WppRichtext } from '@wppopen/components-library-react'
import { jsPDF } from 'jspdf'
import { useCallback, useEffect, useState } from 'react'

import './TextEditor.css'

import { useMarkets } from 'api/queries/markets/useMarkets'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { ProjectDetails, RfiQuestion } from 'types/rfis/rfi'

const modules = JSON.stringify({
  toolbar: {
    aliases: {
      // Add image, video and attachments buttons to the embed section of toolbar
      embed: ['link', 'image', 'video', 'attachment'],
    },
  },
  // Enable custom upload handler for image, video and attachment
  imageUpload: true,
  videoUpload: true,
  attachmentUpload: true,
})

function upload(file: File): Promise<string> {
  return new Promise(resolve => {
    const delay = Math.floor(Math.random() * 10000)

    setTimeout(() => resolve(URL.createObjectURL(file)), delay)
  })
}

interface ConvertedQuestion {
  id: string
  name: string
  questions: RfiQuestion[]
}

interface Props {
  questions: ConvertedQuestion[]
  rfiData: ProjectDetails
}

export const RichTextPage = ({ questions, rfiData }: Props) => {
  const { data: pitchTypes } = usePitchTypes()
  const { data: markets = [] } = useMarkets()

  const [value, setValue] = useState('')

  useEffect(() => {
    if (pitchTypes.length === 0 || markets.length === 0 || questions.length === 0) return

    let tmp = `
      <h2>
          <span class="ql-size-xl">${rfiData.projectName}</span>
      </h2>
      <p>
          <span class="ql-size-m">Project Name</span>
      </p>
      <br />

      <h2>
      <span class="ql-size-xl">${pitchTypes?.find(p => p.id === rfiData.pitchTypeId)?.typeDescription}</span>
      </h2>
      <p class="ql-size-m">
      <span class="ql-size-m">Pitch Type</span>
      </p>
      <br />
      

      <h2>
          <span class="ql-size-xl">${rfiData.clientName}</span>
      </h2>
      <p>
          <span class="ql-size-m">Client name</span>
      </p>
      <br />


      <h2>
          <span class="ql-size-xl">${rfiData.marketIds
            ?.map(market => {
              return markets?.find(p => p.id === market)?.name || ''
            })
            .join(', ')}</span>
      </h2>
      <p>
          <span class="ql-size-m">Markets</span>
      </p>
      <br />
    `

    questions.forEach(category => {
      tmp += `
        <h2>
          <span class="ql-size-xl">${category.name}</span>
        </h2>

        ${category.questions
          .map(question => {
            const proposedAnswer = (question.proposedAnswer || 'Answer not validated/provided').replace(/\n/g, '<br />')
            return `
            <h4>
                <span class="ql-size-l">${question.questionText}</span>
            </h4>
            <p>
                <span class="ql-size-m">${proposedAnswer}</span>
            </p>
            <br />
            `
          })
          .join('')}
        `
    })
    setValue(tmp)
  }, [rfiData, pitchTypes, markets, questions])

  const handleChange = useCallback((e: WppRichtextCustomEvent<RichtextChangeEventDetail>) => {
    console.log('wppChange', e)
    setValue(e.detail.value)
  }, [])

  const handleSelectionChange = useCallback((e: WppRichtextCustomEvent<RichtextSelectionChangeEventDetail>) => {
    console.log('wppSelectionChange', { ...e.detail.range }, e)
  }, [])

  // There is also need to enable respective embed button in toolbar (image, video and attachment)
  // and respective upload modules (imageUpload, videoUpload and attachmentUpload)
  const handleUploadRequest = useCallback((e: WppRichtextCustomEvent<RichtextUploadRequestEventDetail>) => {
    console.log('wppUploadRequest', e)

    const type = e.detail.type
    const callback = e.detail.callback
    const input = document.createElement('input')

    input.type = 'file'
    input.accept = type === 'attachment' ? '*' : `${type}/*`
    input.multiple = true
    input.onchange = () => {
      const uploadItems = Array.from(input.files!).map(file => ({
        file,
        promise: upload(file),
      }))

      callback(uploadItems)
    }
    input.click()
  }, [])

  const generatePDF = () => {
    // var printWindow = window.open('', '', 'height=400,width=800')

    // if (value && printWindow) {
    //   printWindow.document.write('<html><head><title>DIV Contents</title>')
    //   printWindow.document.write('</head><body >')
    //   printWindow.document.write(value)
    //   printWindow.document.write('</body></html>')
    //   printWindow.document.close()
    //   printWindow.print()
    // }

    let pdf = new jsPDF('p', 'pt', 'a4')

    // pdf.splitTextToSize()

    const wrappedValue = `<div style="width: 600px; padding: 50px; position: relative">${value}</div>`

    pdf.html(wrappedValue, {
      callback: function (pdf) {
        pdf.save(`${rfiData.projectName}.pdf`)
        // window.open(output('bloburl'))
      },
    })
  }

  return (
    <>
      {/* <h3>Rich text</h3> */}
      <WppRichtext
        name="content"
        value={value}
        modules={modules}
        onWppChange={handleChange}
        onWppSelectionChange={handleSelectionChange}
        onWppUploadRequest={handleUploadRequest}
        required
        className="mt-10"
      />

      <div className="mt-6 flex justify-end">
        <WppButton size="m" onClick={() => generatePDF()}>
          <WppIconExport slot="icon-start" />
          Export
        </WppButton>
      </div>
      {/* <h3>Rich text view</h3> */}
      {/* <WppRichtextView value={value} /> */}
      {/* <h3>Rich text HTML view</h3> */}
      {/* <WppRichtextHtml value={value} /> */}
    </>
  )
}
