import { FileUploadEventDetail } from '@wppopen/components-library'
import { WppFileUploadCustomEvent } from '@wppopen/components-library/dist/types/components'
import { WppFileUpload, WppInput, WppListItem, WppSelect, WppTypography } from '@wppopen/components-library-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGenerateTempUploadUrls } from 'api/mutations/pdfs/useGenerateTempUploadUrls'
import { useMarkets } from 'api/queries/markets/useMarkets'
import { useRegions } from 'api/queries/markets/useRegions'
import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { useTasksStatus } from 'api/queries/task-status/useTasksStatus'
import { EMPTY_PROJECT, ProjectState } from 'app/App'
import { LoaderProgressWithDescription } from 'components/LoaderProgressWithDescription'
import useProjectContext from 'hooks/useProjectContext'
import { useToast } from 'hooks/useToast'
import { uploadProcessFileApi } from 'utils/uploadProcessFileApi'

export default function NewProjectPage() {
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()
  const { data: markets = [] } = useMarkets()
  const { data: regions = [] } = useRegions()
  const { data: pitchTypes = [] } = usePitchTypes()
  const { state, setState } = useProjectContext()
  const { data: taskStatus } = useTasksStatus({
    enabled: !!state.newTaskStarted?.id,
    refetchInterval: 2000,
    params: { taskId: state.newTaskStarted?.id || '' },
  })
  const rfiSignUrl = useGenerateTempUploadUrls()
  const toast = useToast()

  useEffect(() => {
    if (taskStatus?.completed && !taskStatus.error) {
      setState(prev => ({
        ...prev,
        projectId: taskStatus?.resultObjectId || null,
        newTaskStarted: null,
      }))
      toast.showToast({
        message: 'Project created successfully',
        type: 'success',
      })
      navigate(`/rfi-helper-tool/rfi-summary/${taskStatus?.resultObjectId || ''}`)
    }
    if (taskStatus?.error) {
      setState(prev => ({
        ...prev,
        newTaskStarted: null,
      }))
      toast.showToast({
        message: 'Something went wrong. Failed to create project...',
        type: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStatus?.completed])

  const updateState = (key: string, value: string | string[]) => {
    setState(
      prev =>
        ({
          ...prev,
          newProject: {
            ...prev.newProject,
            [key]: value,
          },
        }) as ProjectState,
    )
  }

  const onUploadFile = (e: WppFileUploadCustomEvent<FileUploadEventDetail>) => {
    if (e.detail.value?.length === 0) {
      setState(prev => ({
        ...prev,
        newProject: {
          ...prev.newProject,
          rfiProjectPdf: EMPTY_PROJECT.rfiProjectPdf,
        },
      }))
      return
    }

    setIsProcessing(true)
    rfiSignUrl
      .mutateAsync({
        names: e.detail.value?.map(file => file.name) || [],
      })
      .then(resGeneratedSignedUrl => {
        if (resGeneratedSignedUrl.status === 200 && e.detail.value) {
          const signedUrl = resGeneratedSignedUrl.data[0].signed_url
          uploadProcessFileApi({
            signedUrl: signedUrl,
            file: e.detail.value?.[0] as File,
          })
            .then(() => {
              const projectName = resGeneratedSignedUrl.data[0].name
              setIsProcessing(false)
              setState(
                prev =>
                  ({
                    ...prev,
                    newProject: {
                      ...prev.newProject,
                      rfiProjectPdf: {
                        key: resGeneratedSignedUrl.data[0].key,
                        folder: 'project',
                        name: projectName || '',
                        size: e.detail.value?.[0].size || 0,
                      },
                    },
                  }) as ProjectState,
              )
            })
            .catch(() => {
              setIsProcessing(false)
              setState(prev => ({
                ...prev,
                newProject: EMPTY_PROJECT,
              }))
              toast.showToast({
                type: 'error',
                message: 'Failed to upload file',
              })
            })
        }
      })
  }

  const uploadDisabled =
    !state.newProject?.client ||
    !state.newProject?.projectName ||
    !state.newProject?.pitchTypeId ||
    state.newProject.rfiProjectPdf.name.length > 0 ||
    (state.newProject?.marketIds?.length || 0) < 1

  const disabledInputs = isProcessing || (state.newTaskStarted !== null && !taskStatus.completed)

  return (
    <div className="flex flex-col gap-3">
      <WppTypography type="2xl-heading">New Project</WppTypography>
      <div className="mt-4 flex flex-col gap-3">
        <WppTypography type="xl-heading">Information</WppTypography>
        <div className="flex flex-row gap-3">
          <div className="flex-1">
            <WppInput
              value={state.newProject?.projectName}
              disabled={disabledInputs}
              onWppChange={e => updateState('projectName', e.detail.value || '')}
              required
              labelConfig={{
                text: 'Project Name',
              }}
            />
          </div>
          <div className="flex-1">
            <WppSelect
              disabled={disabledInputs}
              value={state.newProject?.pitchTypeId}
              onWppChange={e => updateState('pitchTypeId', e.detail.value || '')}
              required
              labelConfig={{
                text: 'Pitch Type',
              }}
            >
              {pitchTypes.map(pitchType => (
                <WppListItem key={pitchType.id} value={pitchType.id}>
                  <p slot="label">{pitchType.typeDescription}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </div>
        </div>
        <div className="flex flex-row gap-3">
          <div className="flex-1">
            <WppInput
              disabled={disabledInputs}
              value={state.newProject?.client || ''}
              onWppChange={e => updateState('client', e.detail.value || '')}
              required
              labelConfig={{
                text: 'Client Name',
              }}
            />
          </div>
          <div className="flex-1">
            <WppSelect
              required
              disabled={disabledInputs}
              withSearch={true}
              showSelectAllText={true}
              type="multiple"
              value={state.newProject?.marketIds || []}
              onWppChange={e => updateState('marketIds', e.detail.value)}
              // onSelect={a => console.log('a', a)}
              labelConfig={{
                text: 'Markets',
              }}
            >
              {[...regions, ...markets].map(market => (
                <WppListItem key={market.id} value={market.id}>
                  <p slot="label">{market.name}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </div>
        </div>
      </div>
      <WppTypography type="xl-heading" className="mt-4">
        Upload files
      </WppTypography>
      <WppFileUpload
        disabled={uploadDisabled}
        hidden={isProcessing || (state.newTaskStarted !== null && !taskStatus.completed)}
        acceptConfig={{ 'application/pdf': ['.pdf'] }}
        onWppChange={onUploadFile}
      />
      {/* TODO: adjust it once the backed is ready to upload RFI */}
      {(isProcessing || (state.newTaskStarted !== null && !taskStatus.completed)) && (
        <div className="h-[146px] rounded-lg w-full flex flex-row items-center justify-center p-[10px] bg-[#F8F9FB]">
          <LoaderProgressWithDescription taskStatus={taskStatus} />
        </div>
      )}
      {state.newTaskStarted !== null && !taskStatus.completed ? (
        <div className="flex flex-row items-center justify-start bg-[#ffffcc] rounded-lg border border-solid border-[#ffeb3b] gap-3 mt-4 px-5 py-4">
          <WppTypography type="s-body">
            The project will be created once the file is uploaded and processed. Please wait...
          </WppTypography>
        </div>
      ) : null}
    </div>
  )
}
